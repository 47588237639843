import {Breadcrumb, ErrorEvent} from '@sentry/react';

const BREADCRUMB_MAX_AGE_MS = 5000;

export default function isProbablyBlockedByAdblockNetworkError(error: ErrorEvent): boolean {
  const now = Date.now();

  if (!error.breadcrumbs) {
    return false;
  }

  // We go from the back since the last breadcrumb is most likely the erroneous one
  for (let i = error.breadcrumbs.length - 1; i >= 0; i--) {
    const breadcrumb = error.breadcrumbs[i];
    if (!breadcrumb) continue;

    // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
    if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > BREADCRUMB_MAX_AGE_MS) {
      break;
    }

    if (isProbablyBlockedByAdblockBreadcrumb(breadcrumb)) {
      return true;
    }
  }

  return false;
}

function isProbablyBlockedByAdblockBreadcrumb(breadcrumb: Breadcrumb): boolean {
  if (breadcrumb.level !== 'error' || (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')) {
    return false;
  }

  const url = breadcrumb.data?.url as string | undefined;
  if (!url) return false;

  return isProbablyBlockedByAdblockUrl(url);
}

function isProbablyBlockedByAdblockUrl(url: string): boolean {
  return ignoredUrls.some(ignoredUrl => ignoredUrl.test(url));
}

const ignoredUrls = [/ups\.xplosion\.de/];
