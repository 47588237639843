import { useEffect } from 'react';
import { generateUniqueTemplateKey } from 'app/helpers/gtm';
import { sendEventToGA4 } from 'app/helpers/gtagHelpers';
const defaultTrackTimeOnPage = (startTime, template) => {
  const endTime = Date.now();
  const timeSpent = Math.round(endTime - startTime); // time in milliseconds
  sendEventToGA4('time_on_page', {
    time_spent: timeSpent,
    content_group: template,
    fupa_tech_metric: 'time_on_page'
  });
};
const defaultGetStartTime = () => Date.now();
const useTrackTimeOnPage = function () {
  let trackTimeOnPage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultTrackTimeOnPage;
  let getStartTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultGetStartTime;
  useEffect(() => {
    const {
      template
    } = generateUniqueTemplateKey(document.location.pathname);
    let startTime = getStartTime();
    const handleBeforeUnload = () => trackTimeOnPage(startTime, template);
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        trackTimeOnPage(startTime, template);
      } else if (document.visibilityState === 'visible') {
        startTime = getStartTime(); // Reset start time when page becomes visible again
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      trackTimeOnPage(startTime, template);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [trackTimeOnPage, getStartTime]);
  return null;
};
export default useTrackTimeOnPage;