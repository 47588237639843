import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {reduxSelectDistrict} from 'app/hooks/reduxSelectDistrict';
import {updateLastDistricts} from 'app/helpers/lastItemsHelpers';

export default function useLastDistricts(districtSlug: string) {
  const district = useSelector(reduxSelectDistrict);
  useEffect(() => {
    if (!district) {
      return;
    }
    const {slug, name, region} = district;
    if (!slug || !name || !region) {
      return;
    }
    if (slug !== districtSlug) {
      return;
    }
    const districtData = {
      slug,
      name,
      regionSlug: region.slug,
    };
    updateLastDistricts(districtData);
  }, [district, districtSlug]);
}
