import isProbablyBlockedByAdblockNetworkError from 'app/services/sentry/isProbablyBlockedByAdBlockNetworkError';
import {ErrorEvent} from '@sentry/react';

export default function sentryBeforeSendHandler(event: ErrorEvent): ErrorEvent | null {
  return shouldIgnoreError(event) ? null : event;
}

function shouldIgnoreError(error: ErrorEvent): boolean {
  return isProbablyBlockedByAdblockNetworkError(error);
}
