import React, {useEffect} from 'react';
import * as Sentry from '@sentry/react';
import {Provider} from 'react-redux';
import makeStore from 'app/services/store';
import createHistory from 'app/services/history';
import ReactDOM from 'react-dom';
import {loadableReady} from '@loadable/component';
import {fetchSelectedDistrict} from 'app/actions/sessionActions';
import {AppShell} from 'app/components/AppShell';
import {HelmetProvider} from 'react-helmet-async';
import {ThemeProvider} from 'styled-components';
import * as media from 'app/styles/media';
import * as cssConstants from 'app/styles/cssConstants';
import {StyledEngineProvider} from '@mui/material';
import {ThemeProvider as MuiThemeProvider} from '@mui/material';
import materialTheme from 'app/styles/materialTheme';
import {ignoreErrorMessage} from 'app/helpers/sentryIgnoreErrors';
import {useConstructor} from 'app/hooks/useConstructor';
import {destroyParallax} from 'app/components/ads/useParallax';
import {reportWebVitals} from 'app/helpers/reportWebVitals';
import {isTemplateBlacklistedForAdRefresh, destroySoDSkin} from 'app/components/ads/adHelpers';
import createEmotionCache from 'app/styles/createEmotionCache';
import {CacheProvider} from '@emotion/react';
import routes from 'app/components/csrRoutesConfig';
import {destroyLazyLoadListener} from 'app/components/ads/lazyLoadHelpers';
import {checkCurrentAppRevision} from 'app/services/getAppRevision';
import {windowIsDefined} from 'app/helpers/windowIsDefined';
import {matchPath, Router} from 'react-router';
import {LicenseInfo} from '@mui/x-license-pro';
import {NativeProvider} from 'app/contexts/NativeContext';
import {isNativeApp, isNativeFlutterApp} from 'app/helpers/nativeHelpers';
import {ComponentErrorHandler} from 'app/components/error/ComponentErrorHandler';
import sentryBeforeSendHandler from 'app/services/sentry/beforeSendHandler';

const history = createHistory();
let root = '/';
if (windowIsDefined) {
  root = window.location.pathname;
  LicenseInfo.setLicenseKey(process.env.MUI_X_PRO_LICENCE_KEY);
}

const store = makeStore(root);
const cache = createEmotionCache();

const App = () => {
  const location = history.location;
  // Important: Register useConstructor to destroy AdSlots before any Router gets rendered - don't move to child component like AppShell
  // Otherwise back-navigation does not have correct order - slots get defined by useUpdateAdSlot before destroySlots is executed
  // Handler of Router would be registered first and execute first
  useConstructor(() => {
    // Need to run before rendering
    if (isNativeFlutterApp()) {
      window.handleNativeResume = () => {};
    }
    let prevLocation = location;

    history.listen(location => {
      let isMatchPage, isBlacklistedTemplate;
      if (prevLocation) {
        // Prevent execution of destroySlots for location change:
        // From BestPlayerVoting: `/lineup?dialog=best-player-voting` To MatchPage: `/lineup`
        // From MatchPage: `/match/:slug` To MatchPage: `/match/:slug/(info|lineup|standing|duels)
        const prevMatchPage = prevLocation.pathname.startsWith('/match/');
        const currentMatchPage = location.pathname.startsWith('/match/');
        isMatchPage = prevMatchPage && currentMatchPage;

        // Prevent execution of destroySlots for location change without template change
        // From: `/select` To: `/select/bayern`
        // From: `/region/:slug/competitions` to `/region/:slug/competitions/:cat/ageGroup/:slug`
        const currentTemplate = isTemplateBlacklistedForAdRefresh(location.pathname);
        const prevTemplate = isTemplateBlacklistedForAdRefresh(prevLocation.pathname);
        isBlacklistedTemplate = currentTemplate.blacklisted && prevTemplate.template === currentTemplate.template;
      }

      prevLocation = location;
      if (location.hash || isMatchPage || isBlacklistedTemplate) {
        // Prevent execution of destroySlots for location change not based on pathname for:
        // - location.hash:  /photos/:slug#3
        // - isMatchLineupVoting: /match/:matchSlug/lineup?dialog=best-player-voting (direct)
        return;
      }
      destroySoDSkin();
      destroyLazyLoadListener();
      // Destroy all adSlots on page before rendering new route
      window.googletag.cmd.push(() => {
        if (!window?.fupa_web_interstitial) {
          console.log('### Destroy all slots...');
          window.googletag.destroySlots();
        } else {
          const adSlotsOnPage = window.googletag.pubads().getSlots();
          adSlotsOnPage.map(slot =>
            console.log(
              '### Debug-Info Web Interstitial: rest slots after unmount not destroyed: ',
              slot.getSlotElementId()
            )
          );
        }
        destroyParallax();
      });
    });
  });

  // Fetch initial district data, if only slug is set in redux
  useEffect(() => {
    const districtStore = store.getState().session?.district;
    if (!districtStore.id && districtStore.slug && !location.pathname.includes('/region/')) {
      fetchSelectedDistrict(districtStore.slug, undefined, location.pathname)(store.dispatch, store.getState);
    }
  }, []);

  useEffect(() => {
    // Need to run after rendering
    history.listen((_, action) => {
      // Reset Scroll-Position when location is changed, but not when using back-and-forth-buttons
      // https://reacttraining.com/react-router/web/guides/scroll-restoration
      if (action === 'PUSH') {
        window.scrollTo(0, 0);
      }
      console.log('history listen...');
    });
  }, []);

  useEffect(() => {
    const visibilityChangeListener = async () => {
      if (document.visibilityState === 'visible') {
        checkCurrentAppRevision();
      }
    };
    document.addEventListener('visibilitychange', visibilityChangeListener);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeListener);
    };
  }, []);

  return (
    <Provider store={store}>
      <div id='app'>
        <Router history={history}>
          <HelmetProvider>
            <StyledEngineProvider injectFirst>
              <CacheProvider value={cache}>
                <NativeProvider>
                  <MuiThemeProvider theme={materialTheme}>
                    <ThemeProvider theme={{...media, ...cssConstants}}>
                      <Sentry.ErrorBoundary
                        fallback={<ComponentErrorHandler />}
                        beforeCapture={scope => {
                          scope.setLevel('fatal');
                          scope.setTag('type', 'component');
                        }}>
                        <AppShell routes={routes} />
                      </Sentry.ErrorBoundary>
                    </ThemeProvider>
                  </MuiThemeProvider>
                </NativeProvider>
              </CacheProvider>
            </StyledEngineProvider>
          </HelmetProvider>
        </Router>
      </div>
    </Provider>
  );
};

loadableReady(() => {
  if (process.env.ENVIRONMENT !== 'local') {
    // From https://github.com/getsentry/sentry-javascript/issues/3388
    const isAffectByIssue3388 = navigator?.userAgent?.includes('Chrome/74.0.3729');
    Sentry.init({
      dsn: 'https://ce9aabc2bf3c4a4d89d5dab11455e6bd@sentry.io/1808824',
      denyUrls: [/grumi-ip\.js/, /\/gtm\.js/, /\/tag\.min\.js/, /\/gtag\/js/], // exclude known 3rd party urls from tracking
      allowUrls: [
        // capture events only for domain fupa to avoid tracking issues e.g. occurred with ads
        /^https:\/\/([a-z0-9-]*\.)?fupa\.(net|rocks|dev)/,
      ],
      environment: process.env.ENVIRONMENT,
      release: process.env.SENTRY_RELEASE,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.browserApiErrorsIntegration({requestAnimationFrame: !isAffectByIssue3388}),
        Sentry.reactRouterV5BrowserTracingIntegration({
          history,
          routes,
          matchPath,
        }),
        Sentry.thirdPartyErrorFilterIntegration({
          filterKeys: ['fupa-app-sentry-key'],
          behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
        }),
      ],
      beforeSend: sentryBeforeSendHandler,
      tracesSampleRate: 0.0005,
      tracePropagationTargets: [/^https:\/\/www\.fupa\.net/, /^https:\/\/api\.fupa\.net/],
      profilesSampleRate: 1.0, // all traces are profiled -> tracesSampleRate * profilesSampleRate = 0.001 of all transactions are profiled
      ignoreErrors: ignoreErrorMessage,
    });
  }
  ReactDOM.hydrate(<App />, document.getElementById('root'));
});

reportWebVitals();

if (!isNativeApp() && 'serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then(registration => {
        console.log('SW registered: ', registration);
      })
      .catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
  });
}
