import {useSelector} from 'react-redux';
import {reduxSelectDistrict} from 'app/hooks/reduxSelectDistrict';
import {useEffect, useMemo} from 'react';
import LocalStorageManager from 'app/services/web-storage/LocalStorageManager';
import {setDimensionToGA4} from 'app/helpers/gtagHelpers';

const ONE_YEAR_IN_MINUTES = 60 * 24 * 365;
const storageKeyUPRegion = 'fupa-up-region';

export default function useRegionUserProperty() {
  const district = useSelector(reduxSelectDistrict);
  const localStorageManager = useMemo(() => LocalStorageManager.getInstance(), []);
  const storageValue = useMemo(() => localStorageManager.getItem(storageKeyUPRegion), [localStorageManager]);

  useEffect(() => {
    if (!district.region?.slug) {
      return;
    }
    localStorageManager.setItem(storageKeyUPRegion, district.region.slug, ONE_YEAR_IN_MINUTES);
  }, [district.region?.slug, localStorageManager]);

  useEffect(() => {
    const userPropertyValue = district.region?.slug ?? storageValue;
    if (!userPropertyValue) {
      return;
    }
    setDimensionToGA4({user_properties: {fupa_region: userPropertyValue}});
  }, [district.region?.slug, storageValue]);
}
