import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useSponsoringTracking} from 'app/hooks/useSponsoringTracking';
import {useParams} from 'react-router';
import {Typography} from '@fupa/fupa-uikit';

const logoWidth = 90;
const logoHeight = 34;

const Container = styled.div`
  display: flex;
  width: ${logoWidth / 16}rem;
  height: ${logoHeight / 16}rem;
  justify-content: flex-end;

  a {
    display: inherit;
    width: inherit;
    height: inherit;
    justify-content: inherit;
    cursor: pointer;
    text-decoration: none;
  }
`;

const Logo = styled.img`
  align-self: flex-end;
  max-width: ${logoWidth / 16}rem;
  max-height: ${logoHeight / 16}rem;
`;

const LogoPresenting = ({sponsoring, className}) => {
  return (
    <Container className={className}>
      <a data-google-interstitial='false' target='_blank' href={sponsoring.link} rel='nofollow'>
        <Logo
          src={sponsoring.img.path}
          width={sponsoring.img.width}
          height={sponsoring.img.height}
          alt={sponsoring.sponsor}
        />
      </a>
    </Container>
  );
};

const Sponsor = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  gap: 0.25rem;
`;

const SCLogoPresenting = styled(LogoPresenting)`
  &&& {
    height: 1.5rem;
  }
`;

const HeaderSponsoring = ({category, withPresentedByCaption = false, className}) => {
  const {districtSlug} = useParams();

  const sponsoringPartner = {
    liveticker: {
      img: {
        path: '/sponsoring/uhlsport.png',
        width: `${logoWidth}px`,
        height: 'auto',
      },
      sponsor: 'Uhlsport',
      link: 'https://www.uhlsport.com/',
      disableTracking: true,
    },
    match_day_results: {
      img: {
        path: '/sponsoring/avp_logo.jpg',
        width: `${logoWidth}px`,
        height: 'auto',
      },
      sponsor: 'AVP Autoland',
      link: 'https://www.avp-autoland.de/',
      districts: ['niederbayern', 'niederbayern-ost', 'niederbayern-west'],
    },
  };

  const showSponsoring = useMemo(() => {
    const sponsoring = sponsoringPartner[category];
    if (!sponsoring) {
      return false;
    }
    if (!sponsoring.districts) {
      return true;
    }
    return sponsoring.districts.includes(districtSlug);
  }, [category, districtSlug]);

  if (!showSponsoring) {
    return null;
  }

  if (!sponsoringPartner[category].disableTracking) {
    useSponsoringTracking(sponsoringPartner[category].sponsor, category);
  }

  return withPresentedByCaption ? (
    <Sponsor>
      <Typography style={{fontStyle: 'italic'}} variant='caption1'>
        präsentiert von
      </Typography>
      <SCLogoPresenting sponsoring={sponsoringPartner[category]} className={className} />
    </Sponsor>
  ) : (
    <LogoPresenting sponsoring={sponsoringPartner[category]} className={className} />
  );
};

export {HeaderSponsoring};
