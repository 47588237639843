export interface featureFlag {
  name: string;
  slug: string;
  availableUntil: Date;
}

const PREFIX = 'feat_';

export const ExpFeatDistrictMainPage: featureFlag = {
  name: 'Neues Design der Startseite',
  slug: `${PREFIX}district_main_page`,
  availableUntil: new Date('2025-02-28'),
};

const featureFlags: featureFlag[] = [ExpFeatDistrictMainPage];

export {featureFlags};
