import {useCallback, useRef} from 'react';
import {generateUniqueTemplateKey} from 'app/helpers/gtm';
import {sendEventToGA4} from 'app/helpers/gtagHelpers';
import {generateTrackingService} from 'app/helpers/trackingTrigger';
import {useLocation} from 'react-router';
import {useEffectAfterMount} from 'app/hooks/dataHooks';

export const usePageViewEvent = () => {
  const location = useLocation();
  const pushGA4 = generateTrackingService();
  const previousPageTitle = useRef<string | null>(null);

  const pageViewEvent = useCallback(
    (title: string) => {
      const {template} = generateUniqueTemplateKey(location.pathname);
      pushGA4(
        {page_title: title, page_location: document.location, content_group: template},
        location.pathname + location.search + location.hash,
        event => sendEventToGA4('page_view', event)
      );
    },
    [location]
  );

  useEffectAfterMount(() => {
    pageViewEvent(document.title);
  }, [location.pathname, location.search, location.hash]);

  const triggerPageView = (title: string) => {
    if (previousPageTitle.current === title) {
      return;
    }
    previousPageTitle.current = title;
    pageViewEvent(title);
  };

  return {triggerPageView};
};
