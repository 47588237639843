import LocalStorageManager from 'app/services/web-storage/LocalStorageManager';

interface LastItem {
  slug: string;
}

interface LastCompetition extends LastItem {
  name: string;
}

interface LastDistrict extends LastItem {
  name: string;
  regionSlug: string;
}

export const KEY_LAST_COMPETITIONS = 'last-competitions';
export const KEY_LAST_DISTRICTS = 'last-districts';
const ONE_MONTH_IN_MINUTES = 30 * 24 * 60;

const addItemAtFirstPosition = (lastState: LastItem[], item: LastItem): LastItem[] => {
  return [item, ...lastState];
};

const moveItemToFirstPosition = (lastState: LastItem[], itemToMove: LastItem): LastItem[] => {
  const currentState = lastState.filter(item => item.slug !== itemToMove.slug);
  return [itemToMove, ...currentState];
};

const trimLastItems = (lastState: LastItem[], maxLength: number): LastItem[] => {
  return lastState.slice(0, maxLength);
};

const addOrUpdateItem = (lastState: LastItem[] = [], itemToUpdate: LastItem, maxLength: number): LastItem[] => {
  const isAlreadyInState = lastState.some(item => item.slug === itemToUpdate.slug);

  const updatedState = isAlreadyInState
    ? moveItemToFirstPosition(lastState, itemToUpdate)
    : addItemAtFirstPosition(lastState, itemToUpdate);

  return trimLastItems(updatedState, maxLength);
};

export const updateLastCompetitions = (newCompetition: LastCompetition): void => {
  updateLastItems(KEY_LAST_COMPETITIONS, newCompetition, 5);
};

export const updateLastDistricts = (newDistrict: LastDistrict): void => {
  updateLastItems(KEY_LAST_DISTRICTS, newDistrict, 7);
};

const updateLastItems = (
  key: string,
  newLastItem: LastItem,
  maxLength: number,
  expirationInMinutes: number = ONE_MONTH_IN_MINUTES
): void => {
  const localStorageManager = LocalStorageManager.getInstance();
  const currentLastItems = localStorageManager.getItem(key) ?? [];
  const newLastItems = addOrUpdateItem(currentLastItems, newLastItem, maxLength);
  localStorageManager.setItem(key, newLastItems, expirationInMinutes);
};
